<template>
  <div>
    <el-tabs v-model="form.status"
             @tab-click="search">
      <el-tab-pane label="未审核"
                   name="1">
      </el-tab-pane>
      <el-tab-pane label="已通过"
                   name="2"></el-tab-pane>
      <el-tab-pane label="未通过"
                   name="3"></el-tab-pane>
    </el-tabs>
    <el-form :model="form"
             label-width="80px"
             ref="formRef">
      <el-row>
        <el-form-item label="科目：">
          <span v-for="item in subjectList"
                :key="item.subject_id"
                :class="{'is_active':item.subject_id == form.subjectId,'subject':true}"
                @click="setSubject(item.subject_id)">{{item.subject_name}}</span>
        </el-form-item>
      </el-row>

      <el-row>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="文件名称:">
            <el-input clearable
                      placeholder="请输入文件名称"
                      v-model="form.filename" />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="开始时间:">
            <el-date-picker v-model="form.beganTime"
                            style="width:100%"
                            @focus="$forbid"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="开始时间" />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="结束时间:">
            <el-date-picker v-model="form.endTime"
                            style="width:100%"
                            @focus="$forbid"
                            type="datetime"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="结束时间" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10"
              style="padding:10px 0;">
        <el-col :span="24">
          <el-button type="primary"
                     @click="search">查询</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table border
              stripe
              :data="tableData"
              highlight-current-row
              :max-height="$store.state.tableHeight + 30"
              style="width: 100%">
      <el-table-column prop="filename"
                       v-if="tableColumns[0].isShow"
                       align="center"
                       label="文件名称">
        <template slot-scope="{row}">
          <div class="line"
               @click="preview(row)">
            {{row.filename}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="subject_name"
                       v-if="tableColumns[1].isShow"
                       align="center"
                       label="科目" />
      <el-table-column prop="type_name"
                       v-if="tableColumns[2].isShow"
                       align="center"
                       label="文件类型" />
      <el-table-column prop="file_size"
                       v-if="tableColumns[3].isShow"
                       align="center"
                       label="大小" />
      <el-table-column prop="created_time"
                       v-if="tableColumns[4].isShow"
                       align="center"
                       label="上传时间" />
      <el-table-column prop="end_time"
                       v-if="tableColumns[5].isShow"
                       align="center"
                       label="截止时间" />
      <el-table-column prop="description"
                       v-if="tableColumns[6].isShow"
                       align="center"
                       label="描述" />
      <el-table-column align="center"
                       v-if="tableColumns[7].isShow"
                       label="操作">
        <template slot="header">
          <i class="el-icon-setting"
             slot="header"
             @click="showController"></i>
          操作
        </template>

        <template slot-scope="{row}">

          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="预览"
                        placement="top-start">
              <img src="@/assets/icons/icon-priview@2x.png"
                   @click="preview(row)"
                   alt="">
            </el-tooltip>

          </div>
        </template>
      </el-table-column>
    </el-table>
    <Shuttle ref="ShuttleRef" />

    <previewDialog ref="previewDialogRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="limit"
                :total="totalElements" />
  </div>
</template>

<script>
import previewDialog from './components/preview.vue'
export default {
  data () {
    return {
      tableData: [],
      subjectList: [],
      fileTypeList: [],
      limit: 10,
      page: 1,
      totalElements: 0,
      form: {
        subjectId: '',
        type: '',
        status: '1',
        filename: '',
        beganTime: '',
        endTime: '',
        limit: 10,
        page: 1,
      },
      pageName: 'cz_teacher_module_auditList_admin',
      tableColumns: [
        { label: '文件名称', isShow: true },
        { label: '科目', isShow: true },
        { label: '文件类型', isShow: true },
        { label: '大小', isShow: true },
        { label: '上传时间', isShow: true },
        { label: '截止时间', isShow: true },
        { label: '描述', isShow: true },
        { label: '操作', isShow: true },

      ],
    }
  },
  components: {
    previewDialog
  },
  created () {
    var info = window.localStorage.getItem(this.pageName)
    if (info) {
      this.tableColumns = JSON.parse(info)
    }
  },
  mounted () {
    this.initSelect()
  },
  methods: {
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      this.$http({
        url: '/api/v1/course/audit_lst',
        method: 'post',
        data: this.form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    initSelect () {
      this.$http({
        url: '/api/v1/public/teacher_subject_lst',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
      this.$http({
        url: '/api/v1/file/type',
        method: 'get'
      }).then(res => {
        this.fileTypeList = res.data.list
      })
    },
    preview (row) {
      let form = {
        id: row.id,
        status: '',
        reason: ''
      }
      this.$refs.previewDialogRef.text = row.filename
      this.$refs.previewDialogRef.info = row
      this.$refs.previewDialogRef.setIframe()
      this.$refs.previewDialogRef.form = form
      this.$refs.previewDialogRef.dialogVisible = true
    },
    setSubject (id) {
      this.form.subjectId = id
      this.search()
    },
    setTypeValue (value) {
      this.form.type = value
      this.search()
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  font-size: 19px;
  width: 150px;
  text-align: center;
  padding-right: 10px;
  padding: 0;
}

.is_active {
  color: #409eff;
}
.subject {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}

.line {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
</style>